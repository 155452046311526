/**
 * Aivie Contact API
 * Get an Aivie contact
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ContactFieldsAll model module.
 * @module model/ContactFieldsAll
 * @version 0.1.1
 */
class ContactFieldsAll {
    /**
     * Constructs a new <code>ContactFieldsAll</code>.
     * @alias module:model/ContactFieldsAll
     */
    constructor() { 
        
        ContactFieldsAll.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ContactFieldsAll</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ContactFieldsAll} obj Optional instance to populate.
     * @return {module:model/ContactFieldsAll} The populated <code>ContactFieldsAll</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ContactFieldsAll();

            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('firstname')) {
                obj['firstname'] = ApiClient.convertToType(data['firstname'], 'String');
            }
            if (data.hasOwnProperty('lastname')) {
                obj['lastname'] = ApiClient.convertToType(data['lastname'], 'String');
            }
            if (data.hasOwnProperty('company')) {
                obj['company'] = ApiClient.convertToType(data['company'], 'String');
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('mobile')) {
                obj['mobile'] = ApiClient.convertToType(data['mobile'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('fax')) {
                obj['fax'] = ApiClient.convertToType(data['fax'], 'String');
            }
            if (data.hasOwnProperty('address1')) {
                obj['address1'] = ApiClient.convertToType(data['address1'], 'String');
            }
            if (data.hasOwnProperty('address2')) {
                obj['address2'] = ApiClient.convertToType(data['address2'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
            if (data.hasOwnProperty('state')) {
                obj['state'] = ApiClient.convertToType(data['state'], 'String');
            }
            if (data.hasOwnProperty('zipcode')) {
                obj['zipcode'] = ApiClient.convertToType(data['zipcode'], 'String');
            }
            if (data.hasOwnProperty('country')) {
                obj['country'] = ApiClient.convertToType(data['country'], 'String');
            }
            if (data.hasOwnProperty('preferred_locale')) {
                obj['preferred_locale'] = ApiClient.convertToType(data['preferred_locale'], 'String');
            }
            if (data.hasOwnProperty('timezone')) {
                obj['timezone'] = ApiClient.convertToType(data['timezone'], 'String');
            }
            if (data.hasOwnProperty('attribution_date')) {
                obj['attribution_date'] = ApiClient.convertToType(data['attribution_date'], 'Date');
            }
            if (data.hasOwnProperty('attribution')) {
                obj['attribution'] = ApiClient.convertToType(data['attribution'], 'String');
            }
            if (data.hasOwnProperty('website')) {
                obj['website'] = ApiClient.convertToType(data['website'], 'String');
            }
            if (data.hasOwnProperty('facebook')) {
                obj['facebook'] = ApiClient.convertToType(data['facebook'], 'String');
            }
            if (data.hasOwnProperty('foursquare')) {
                obj['foursquare'] = ApiClient.convertToType(data['foursquare'], 'String');
            }
            if (data.hasOwnProperty('googleplus')) {
                obj['googleplus'] = ApiClient.convertToType(data['googleplus'], 'String');
            }
            if (data.hasOwnProperty('instagram')) {
                obj['instagram'] = ApiClient.convertToType(data['instagram'], 'String');
            }
            if (data.hasOwnProperty('linkedin')) {
                obj['linkedin'] = ApiClient.convertToType(data['linkedin'], 'String');
            }
            if (data.hasOwnProperty('skype')) {
                obj['skype'] = ApiClient.convertToType(data['skype'], 'String');
            }
            if (data.hasOwnProperty('twitter')) {
                obj['twitter'] = ApiClient.convertToType(data['twitter'], 'String');
            }
            if (data.hasOwnProperty('last_message')) {
                obj['last_message'] = ApiClient.convertToType(data['last_message'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} title
 */
ContactFieldsAll.prototype['title'] = undefined;

/**
 * @member {String} firstname
 */
ContactFieldsAll.prototype['firstname'] = undefined;

/**
 * @member {String} lastname
 */
ContactFieldsAll.prototype['lastname'] = undefined;

/**
 * @member {String} company
 */
ContactFieldsAll.prototype['company'] = undefined;

/**
 * @member {String} position
 */
ContactFieldsAll.prototype['position'] = undefined;

/**
 * @member {String} email
 */
ContactFieldsAll.prototype['email'] = undefined;

/**
 * @member {String} mobile
 */
ContactFieldsAll.prototype['mobile'] = undefined;

/**
 * @member {String} phone
 */
ContactFieldsAll.prototype['phone'] = undefined;

/**
 * @member {String} fax
 */
ContactFieldsAll.prototype['fax'] = undefined;

/**
 * @member {String} address1
 */
ContactFieldsAll.prototype['address1'] = undefined;

/**
 * @member {String} address2
 */
ContactFieldsAll.prototype['address2'] = undefined;

/**
 * @member {String} city
 */
ContactFieldsAll.prototype['city'] = undefined;

/**
 * @member {String} state
 */
ContactFieldsAll.prototype['state'] = undefined;

/**
 * @member {String} zipcode
 */
ContactFieldsAll.prototype['zipcode'] = undefined;

/**
 * @member {String} country
 */
ContactFieldsAll.prototype['country'] = undefined;

/**
 * @member {String} preferred_locale
 */
ContactFieldsAll.prototype['preferred_locale'] = undefined;

/**
 * @member {String} timezone
 */
ContactFieldsAll.prototype['timezone'] = undefined;

/**
 * the date-time notation as defined by RFC 3339 in UTC
 * @member {Date} attribution_date
 */
ContactFieldsAll.prototype['attribution_date'] = undefined;

/**
 * @member {String} attribution
 */
ContactFieldsAll.prototype['attribution'] = undefined;

/**
 * @member {String} website
 */
ContactFieldsAll.prototype['website'] = undefined;

/**
 * @member {String} facebook
 */
ContactFieldsAll.prototype['facebook'] = undefined;

/**
 * @member {String} foursquare
 */
ContactFieldsAll.prototype['foursquare'] = undefined;

/**
 * @member {String} googleplus
 */
ContactFieldsAll.prototype['googleplus'] = undefined;

/**
 * @member {String} instagram
 */
ContactFieldsAll.prototype['instagram'] = undefined;

/**
 * @member {String} linkedin
 */
ContactFieldsAll.prototype['linkedin'] = undefined;

/**
 * @member {String} skype
 */
ContactFieldsAll.prototype['skype'] = undefined;

/**
 * @member {String} twitter
 */
ContactFieldsAll.prototype['twitter'] = undefined;

/**
 * @member {String} last_message
 */
ContactFieldsAll.prototype['last_message'] = undefined;






export default ContactFieldsAll;

