/**
 * Aivie Contact API
 * Get an Aivie contact
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Tag model module.
 * @module model/Tag
 * @version 0.1.1
 */
class Tag {
    /**
     * Constructs a new <code>Tag</code>.
     * @alias module:model/Tag
     * @param id {Number} 
     * @param tag {String} 
     */
    constructor(id, tag) { 
        
        Tag.initialize(this, id, tag);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, tag) { 
        obj['id'] = id;
        obj['tag'] = tag;
    }

    /**
     * Constructs a <code>Tag</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Tag} obj Optional instance to populate.
     * @return {module:model/Tag} The populated <code>Tag</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Tag();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tag')) {
                obj['tag'] = ApiClient.convertToType(data['tag'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
Tag.prototype['id'] = undefined;

/**
 * @member {String} tag
 */
Tag.prototype['tag'] = undefined;






export default Tag;

