/**
 * Aivie Contact API
 * Get an Aivie contact
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ContactFieldsAll from './ContactFieldsAll';

/**
 * The ContactFields model module.
 * @module model/ContactFields
 * @version 0.1.1
 */
class ContactFields {
    /**
     * Constructs a new <code>ContactFields</code>.
     * @alias module:model/ContactFields
     * @param all {module:model/ContactFieldsAll} 
     */
    constructor(all) { 
        
        ContactFields.initialize(this, all);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, all) { 
        obj['all'] = all;
    }

    /**
     * Constructs a <code>ContactFields</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ContactFields} obj Optional instance to populate.
     * @return {module:model/ContactFields} The populated <code>ContactFields</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ContactFields();

            if (data.hasOwnProperty('all')) {
                obj['all'] = ContactFieldsAll.constructFromObject(data['all']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/ContactFieldsAll} all
 */
ContactFields.prototype['all'] = undefined;






export default ContactFields;

