import Token from './model/token.model';
import { DefaultApi, ApiClient, InlineResponse400 } from '../api/mautic/src';

export function getMauticIdFromUrl(url: string): number {
  if (!url || url.length <= 5) {
    console.warn('no valid url', url);
    return 0;
  }
  const pos = url.lastIndexOf('/') + 1;
  const lastPart = url.substring(pos);
  return Number(lastPart);
}
export function getMauticAttachment(attachments: any): number {
  // /s/contacts/view/1
  return attachments.find((att) => att.url.indexOf('/s/contacts/view/') > 0);
}
/**
 * Check all attachments for a Mautic Url, return the Client Id on Successs
 * @param {Attachment} attachments
 */
export function getMauticContactIdFromAttachments(attachments: any): number {
  const attachment = getMauticAttachment(attachments);
  // /s/contacts/view/1
  return getMauticIdFromUrl(attachment.url);
}
/**
 * Get the attachents for the current card and find the mautic url
 * to retrieve the Mautic contact id
 * @param {Trello} t
 */
export function getMauticIdForActiveCard(t: any): Promise<number> {
  return t.card('attachments').then((card) => {
    if (!card.attachments) {
      return Promise.resolve(null);
    }
    const contactId = getMauticContactIdFromAttachments(card.attachments);
    if (!contactId || contactId <= 0) {
      return Promise.resolve(null); // no attachment, or no mautic id, ignore
    }
    return Promise.resolve(contactId);
  });
}

export function getStoredData(trello, key: string): Promise {
  // console.debug('start getting stored data', key);
  return Promise.all([trello.getAll(), trello.member('all')])
    .then(([data, user]) => {
      // console.debug('stored data', data);

      // board
      if (data && data.board && data.board.shared && key in data.board.shared) {
        return data.board.shared[key];
      }
      if (data && data.board && data.board.private && key in data.board.private) {
        return data.board.private[key];
      }
      // member
      if (data && data.member && data.member.private && key in data.member.private) {
        if (key.substr(0, 5) === 'token') {
          return new Token(data.member.private[key]);
        }
        return data.member.private[key];
      }
      // user
      if (user && user[key]) {
        return user[key];
      }
      return null;
    })
    .catch((error) => {
      console.warn(error);
      throw new Error(error.message);
    });
}

/**
 * Get the automatic refresh interval of e.g. a badge in seconds
 */
export function getRefreshInterval() {
  const random = Math.random(); // between 0 and 1
  return 29 * 60 + 1 * random; // between 29 and 30 minutes in the future
  // return 30;
}

export function getApiClient(t: any): ApiClient {
  return Promise.all([getStoredData(t, 'mauticUrl')])
    .then(([mauticUrl]) => {
      if (!mauticUrl) {
        throw new Error('No mauticUrl for API');
      }

      const defaultClient = new ApiClient();
      defaultClient.basePath = mauticUrl;

      // return new DefaultApi(defaultClient);
      return defaultClient;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
}
export function postApiData(
  api: DefaultApi,
  path: String,
  data: Object,
  returnType: Object
): Promise<any> {
  function handleApiError(response: Response) {
    // console.debug(response.body);
    const inline400: InlineResponse400 = InlineResponse400.constructFromObject(response.body);

    if (inline400 instanceof InlineResponse400 && inline400.errors[0]) {
      throw new Error(inline400.errors[0].message);
    } else {
      throw new Error('Could not post data to API');
    }
  }

  if (!api) {
    throw new Error('no API');
  }
  const pathParams = {};
  const queryParams = {};
  // const headerParams = {};
  const headerParams = { 'X-Requested-With': 'XMLHttpRequest' };
  const formParams = {};
  const authNames = [];
  const contentTypes = [];
  const accepts = ['application/json']; // maybe needs to be dynamic
  return api
    .callApi(
      path,
      'POST',
      pathParams,
      queryParams,
      headerParams,
      formParams,
      data,
      authNames,
      contentTypes,
      accepts,
      returnType
    )
    .then((res) => {
      const { response }: Response = res;
      if (!response || (response.status < 200 && response.status > 204)) {
        throw new Error('Could not Post to API');
      }
      return res.data;
    })
    .catch(handleApiError);
}
