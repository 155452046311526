/**
 * Aivie Contact API
 * Get an Aivie contact
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import InlineResponse200 from '../model/InlineResponse200';
import InlineResponse2001 from '../model/InlineResponse2001';
import InlineResponse400 from '../model/InlineResponse400';
import MauticContact from '../model/MauticContact';
import MauticForm from '../model/MauticForm';

/**
* Default service.
* @module api/DefaultApi
* @version 0.1.1
*/
export default class DefaultApi {

    /**
    * Constructs a new DefaultApi. 
    * @alias module:api/DefaultApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Add data to a Mautic form (without login). Same as on a website.
     * @param {module:model/MauticForm} mauticForm Contact information as \"#/components/schemas/MauticForm\"
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MauticContact} and HTTP response
     */
    formSubmitWithHttpInfo(mauticForm) {
      let postBody = mauticForm;
      // verify the required parameter 'mauticForm' is set
      if (mauticForm === undefined || mauticForm === null) {
        throw new Error("Missing the required parameter 'mauticForm' when calling formSubmit");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oAuth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = MauticContact;
      return this.apiClient.callApi(
        '/form/submit', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Add data to a Mautic form (without login). Same as on a website.
     * @param {module:model/MauticForm} mauticForm Contact information as \"#/components/schemas/MauticForm\"
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MauticContact}
     */
    formSubmit(mauticForm) {
      return this.formSubmitWithHttpInfo(mauticForm)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Obtains a specific entity as defined by the API URL.
     * Get a contact by id
     * @param {Number} id 
     * @param {String} xRequestedWith 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2001} and HTTP response
     */
    getContactWithHttpInfo(id, xRequestedWith) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getContact");
      }
      // verify the required parameter 'xRequestedWith' is set
      if (xRequestedWith === undefined || xRequestedWith === null) {
        throw new Error("Missing the required parameter 'xRequestedWith' when calling getContact");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
        'X-Requested-With': xRequestedWith
      };
      let formParams = {
      };

      let authNames = ['oAuth2'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2001;
      return this.apiClient.callApi(
        '/api/contacts/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Obtains a specific entity as defined by the API URL.
     * Get a contact by id
     * @param {Number} id 
     * @param {String} xRequestedWith 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2001}
     */
    getContact(id, xRequestedWith) {
      return this.getContactWithHttpInfo(id, xRequestedWith)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Search contacts
     * Get a list of contacts based on search term
     * @param {String} search 
     * @param {String} xRequestedWith 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.minimal 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse200} and HTTP response
     */
    searchContactWithHttpInfo(search, xRequestedWith, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'search' is set
      if (search === undefined || search === null) {
        throw new Error("Missing the required parameter 'search' when calling searchContact");
      }
      // verify the required parameter 'xRequestedWith' is set
      if (xRequestedWith === undefined || xRequestedWith === null) {
        throw new Error("Missing the required parameter 'xRequestedWith' when calling searchContact");
      }

      let pathParams = {
      };
      let queryParams = {
        'search': search,
        'minimal': opts['minimal']
      };
      let headerParams = {
        'X-Requested-With': xRequestedWith
      };
      let formParams = {
      };

      let authNames = ['oAuth2'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse200;
      return this.apiClient.callApi(
        '/api/contacts', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Search contacts
     * Get a list of contacts based on search term
     * @param {String} search 
     * @param {String} xRequestedWith 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.minimal 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse200}
     */
    searchContact(search, xRequestedWith, opts) {
      return this.searchContactWithHttpInfo(search, xRequestedWith, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
