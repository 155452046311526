/**
 * Aivie Contact API
 * Get an Aivie contact
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MauticFormAllOf model module.
 * @module model/MauticFormAllOf
 * @version 0.1.1
 */
class MauticFormAllOf {
    /**
     * Constructs a new <code>MauticFormAllOf</code>.
     * @alias module:model/MauticFormAllOf
     * @param formId {Number} Mautic Form ID. Form fields 'Field HTML name' must be the same as contact MauticContactNew
     * @param messenger {String} any string, must be set
     */
    constructor(formId, messenger) { 
        
        MauticFormAllOf.initialize(this, formId, messenger);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, formId, messenger) { 
        obj['formId'] = formId;
        obj['messenger'] = messenger;
    }

    /**
     * Constructs a <code>MauticFormAllOf</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MauticFormAllOf} obj Optional instance to populate.
     * @return {module:model/MauticFormAllOf} The populated <code>MauticFormAllOf</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MauticFormAllOf();

            if (data.hasOwnProperty('formId')) {
                obj['formId'] = ApiClient.convertToType(data['formId'], 'Number');
            }
            if (data.hasOwnProperty('return')) {
                obj['return'] = ApiClient.convertToType(data['return'], 'Number');
            }
            if (data.hasOwnProperty('messenger')) {
                obj['messenger'] = ApiClient.convertToType(data['messenger'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Mautic Form ID. Form fields 'Field HTML name' must be the same as contact MauticContactNew
 * @member {Number} formId
 */
MauticFormAllOf.prototype['formId'] = undefined;

/**
 * any string, must be set
 * @member {Number} return
 */
MauticFormAllOf.prototype['return'] = undefined;

/**
 * any string, must be set
 * @member {String} messenger
 */
MauticFormAllOf.prototype['messenger'] = undefined;






export default MauticFormAllOf;

