/**
 * Aivie Contact API
 * Get an Aivie contact
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ContactFields from './ContactFields';
import Stage from './Stage';
import Tag from './Tag';

/**
 * The MauticContact model module.
 * @module model/MauticContact
 * @version 0.1.1
 */
class MauticContact {
    /**
     * Constructs a new <code>MauticContact</code>.
     * @alias module:model/MauticContact
     * @param id {Number} 
     */
    constructor(id) { 
        
        MauticContact.initialize(this, id);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id) { 
        obj['id'] = id;
    }

    /**
     * Constructs a <code>MauticContact</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MauticContact} obj Optional instance to populate.
     * @return {module:model/MauticContact} The populated <code>MauticContact</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MauticContact();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('points')) {
                obj['points'] = ApiClient.convertToType(data['points'], 'Number');
            }
            if (data.hasOwnProperty('stage')) {
                obj['stage'] = Stage.constructFromObject(data['stage']);
            }
            if (data.hasOwnProperty('fields')) {
                obj['fields'] = ContactFields.constructFromObject(data['fields']);
            }
            if (data.hasOwnProperty('tags')) {
                obj['tags'] = ApiClient.convertToType(data['tags'], [Tag]);
            }
            if (data.hasOwnProperty('ipAddress')) {
                obj['ipAddress'] = ApiClient.convertToType(data['ipAddress'], 'String');
            }
            if (data.hasOwnProperty('lastActive')) {
                obj['lastActive'] = ApiClient.convertToType(data['lastActive'], 'Date');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
MauticContact.prototype['id'] = undefined;

/**
 * @member {Number} points
 */
MauticContact.prototype['points'] = undefined;

/**
 * @member {module:model/Stage} stage
 */
MauticContact.prototype['stage'] = undefined;

/**
 * @member {module:model/ContactFields} fields
 */
MauticContact.prototype['fields'] = undefined;

/**
 * array of tags
 * @member {Array.<module:model/Tag>} tags
 */
MauticContact.prototype['tags'] = undefined;

/**
 * IP adress in the format ipv4
 * @member {String} ipAddress
 */
MauticContact.prototype['ipAddress'] = undefined;

/**
 * the date-time notation as defined by RFC 3339 in UTC
 * @member {Date} lastActive
 */
MauticContact.prototype['lastActive'] = undefined;






export default MauticContact;

