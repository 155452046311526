// @flow

class Token {
  accessToken: string;

  /**
   * The number of seconds the access token is good for
   */
  expiresIn: number;

  refreshToken: string;

  /**
   * Date in ISO, Timezone: UTC
   */
  expiration: string;

  /**
   * Not used
   * scope: string;
   */

  constructor(data: Object) {
    if (!data) {
      return null;
    }
    this.accessToken = data.accessToken || null;
    this.expiresIn = data.expiresIn || null;
    this.refreshToken = data.refreshToken || null;
    this.expiration = data.expiration || this.calculateExpirationDate();
  }

  /**
   * Calculate the ISO string of the expiration date
   */
  calculateExpirationDate(): string {
    if (!this.expiresIn) {
      return '';
    }

    const t = new Date();
    // t.setSeconds(t.getSeconds() + 60); // debug
    t.setSeconds(t.getSeconds() + this.expiresIn);
    return t.toISOString();
  }

  isValid() {
    if (!this.accessToken || !this.expiresIn || !this.refreshToken) {
      return false;
    }
    // check if token can be refreshed
    return this.isValidAccessToken();
  }

  isValidAccessToken() {
    // @todo We could do this by making a simple request to our service and ensuring that we get back an expected response.
    // const isPattern = /^[0-9a-f]{64}$/.test(this.accessToken);
    const isPattern = this.accessToken.length === 86;
    const isDate = new Date(this.expiration) >= new Date();

    // if (!isPattern) {
    //   console.debug('Token: no valid pattern');
    // }
    // if (!isDate) {
    //   console.debug('Token: no longer valid expiration date', this.expiration);
    // }
    return isPattern && isDate;
  }
}
export default Token;
