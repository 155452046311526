/**
 * Aivie Contact API
 * Get an Aivie contact
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PersonalInformation model module.
 * @module model/PersonalInformation
 * @version 0.1.1
 */
class PersonalInformation {
    /**
     * Constructs a new <code>PersonalInformation</code>.
     * @alias module:model/PersonalInformation
     * @param firstname {String} 
     * @param email {String} 
     */
    constructor(firstname, email) { 
        
        PersonalInformation.initialize(this, firstname, email);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, firstname, email) { 
        obj['firstname'] = firstname;
        obj['email'] = email;
    }

    /**
     * Constructs a <code>PersonalInformation</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PersonalInformation} obj Optional instance to populate.
     * @return {module:model/PersonalInformation} The populated <code>PersonalInformation</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PersonalInformation();

            if (data.hasOwnProperty('firstname')) {
                obj['firstname'] = ApiClient.convertToType(data['firstname'], 'String');
            }
            if (data.hasOwnProperty('lastname')) {
                obj['lastname'] = ApiClient.convertToType(data['lastname'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('preferred_locale')) {
                obj['preferred_locale'] = ApiClient.convertToType(data['preferred_locale'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} firstname
 */
PersonalInformation.prototype['firstname'] = undefined;

/**
 * @member {String} lastname
 */
PersonalInformation.prototype['lastname'] = undefined;

/**
 * @member {String} email
 */
PersonalInformation.prototype['email'] = undefined;

/**
 * @member {String} phone
 */
PersonalInformation.prototype['phone'] = undefined;

/**
 * @member {String} preferred_locale
 */
PersonalInformation.prototype['preferred_locale'] = undefined;






export default PersonalInformation;

