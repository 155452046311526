/**
 * Aivie Contact API
 * Get an Aivie contact
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MauticContactNewAllOf from './MauticContactNewAllOf';
import PersonalInformation from './PersonalInformation';

/**
 * The MauticContactNew model module.
 * @module model/MauticContactNew
 * @version 0.1.1
 */
class MauticContactNew {
    /**
     * Constructs a new <code>MauticContactNew</code>.
     * @alias module:model/MauticContactNew
     * @implements module:model/PersonalInformation
     * @implements module:model/MauticContactNewAllOf
     * @param firstname {String} 
     * @param email {String} 
     */
    constructor(firstname, email) { 
        PersonalInformation.initialize(this, firstname, email);MauticContactNewAllOf.initialize(this);
        MauticContactNew.initialize(this, firstname, email);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, firstname, email) { 
        obj['firstname'] = firstname;
        obj['email'] = email;
    }

    /**
     * Constructs a <code>MauticContactNew</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MauticContactNew} obj Optional instance to populate.
     * @return {module:model/MauticContactNew} The populated <code>MauticContactNew</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MauticContactNew();
            PersonalInformation.constructFromObject(data, obj);
            MauticContactNewAllOf.constructFromObject(data, obj);

            if (data.hasOwnProperty('firstname')) {
                obj['firstname'] = ApiClient.convertToType(data['firstname'], 'String');
            }
            if (data.hasOwnProperty('lastname')) {
                obj['lastname'] = ApiClient.convertToType(data['lastname'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('preferred_locale')) {
                obj['preferred_locale'] = ApiClient.convertToType(data['preferred_locale'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('points')) {
                obj['points'] = ApiClient.convertToType(data['points'], 'Number');
            }
            if (data.hasOwnProperty('ipAddress')) {
                obj['ipAddress'] = ApiClient.convertToType(data['ipAddress'], 'String');
            }
            if (data.hasOwnProperty('lastActive')) {
                obj['lastActive'] = ApiClient.convertToType(data['lastActive'], 'Date');
            }
            if (data.hasOwnProperty('tags')) {
                obj['tags'] = ApiClient.convertToType(data['tags'], 'String');
            }
            if (data.hasOwnProperty('owner')) {
                obj['owner'] = ApiClient.convertToType(data['owner'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {String} firstname
 */
MauticContactNew.prototype['firstname'] = undefined;

/**
 * @member {String} lastname
 */
MauticContactNew.prototype['lastname'] = undefined;

/**
 * @member {String} email
 */
MauticContactNew.prototype['email'] = undefined;

/**
 * @member {String} phone
 */
MauticContactNew.prototype['phone'] = undefined;

/**
 * @member {String} preferred_locale
 */
MauticContactNew.prototype['preferred_locale'] = undefined;

/**
 * @member {String} title
 */
MauticContactNew.prototype['title'] = undefined;

/**
 * number of points the contact has with Mautic
 * @member {Number} points
 */
MauticContactNew.prototype['points'] = undefined;

/**
 * IP adress in the format ipv4
 * @member {String} ipAddress
 */
MauticContactNew.prototype['ipAddress'] = undefined;

/**
 * the date-time notation as defined by RFC 3339 in UTC
 * @member {Date} lastActive
 */
MauticContactNew.prototype['lastActive'] = undefined;

/**
 * comma separated list of tags
 * @member {String} tags
 */
MauticContactNew.prototype['tags'] = undefined;

/**
 * ID of the MauticUser
 * @member {Number} owner
 */
MauticContactNew.prototype['owner'] = undefined;


// Implement PersonalInformation interface:
/**
 * @member {String} firstname
 */
PersonalInformation.prototype['firstname'] = undefined;
/**
 * @member {String} lastname
 */
PersonalInformation.prototype['lastname'] = undefined;
/**
 * @member {String} email
 */
PersonalInformation.prototype['email'] = undefined;
/**
 * @member {String} phone
 */
PersonalInformation.prototype['phone'] = undefined;
/**
 * @member {String} preferred_locale
 */
PersonalInformation.prototype['preferred_locale'] = undefined;
// Implement MauticContactNewAllOf interface:
/**
 * @member {String} title
 */
MauticContactNewAllOf.prototype['title'] = undefined;
/**
 * number of points the contact has with Mautic
 * @member {Number} points
 */
MauticContactNewAllOf.prototype['points'] = undefined;
/**
 * IP adress in the format ipv4
 * @member {String} ipAddress
 */
MauticContactNewAllOf.prototype['ipAddress'] = undefined;
/**
 * the date-time notation as defined by RFC 3339 in UTC
 * @member {Date} lastActive
 */
MauticContactNewAllOf.prototype['lastActive'] = undefined;
/**
 * comma separated list of tags
 * @member {String} tags
 */
MauticContactNewAllOf.prototype['tags'] = undefined;
/**
 * ID of the MauticUser
 * @member {Number} owner
 */
MauticContactNewAllOf.prototype['owner'] = undefined;




export default MauticContactNew;

