/**
 * Aivie Contact API
 * Get an Aivie contact
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MauticError model module.
 * @module model/MauticError
 * @version 0.1.1
 */
class MauticError {
    /**
     * Constructs a new <code>MauticError</code>.
     * A single Mautic standard API error
     * @alias module:model/MauticError
     * @param message {String} 
     * @param code {Number} 
     * @param type {String} 
     */
    constructor(message, code, type) { 
        
        MauticError.initialize(this, message, code, type);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, message, code, type) { 
        obj['message'] = message;
        obj['code'] = code;
        obj['type'] = type;
    }

    /**
     * Constructs a <code>MauticError</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MauticError} obj Optional instance to populate.
     * @return {module:model/MauticError} The populated <code>MauticError</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MauticError();

            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'Number');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} message
 */
MauticError.prototype['message'] = undefined;

/**
 * @member {Number} code
 */
MauticError.prototype['code'] = undefined;

/**
 * @member {String} type
 */
MauticError.prototype['type'] = undefined;






export default MauticError;

